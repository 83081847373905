import React, { Component } from "react";
import "../styles/header.scss";

class Header extends Component {
  state = {};
  render() {
    return (
      <header id="pageHeader">
        <object
          id="headerLogo"
          data="./images/assets/NowLoadingLogoHeader.svg"
          type="image/svg+xml"
        >
          Now Loading Logo
        </object>
        <nav className="navigation">
          <ul className="ul-nav-flex">
            <li className="navSelect">
              <a href="#Esports">
                <div className="dropDownHeader">Esports</div>
              </a>
            </li>
            <li className="navSelect">
              <a href="#Streaming">
                <div className="dropDownHeader">Streaming</div>
              </a>
            </li>
            <li className="navSelect">
              <a href="#Clients">
                <div className="dropDownHeader">Clients</div>
              </a>
            </li>
            <li className="navSelect">
              <a href="#Business">
                <div className="dropDownHeader">Business</div>
              </a>
            </li>
          </ul>
        </nav>
        <div className="parallelogram"></div>
      </header>
    );
  }
}

export default Header;
