import React, { Component } from "react";
import ReactPlayer from "react-player";

import "../styles/videoContentBlock.scss";

class VideoContentBlock extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id={this.props.blockId} className="videoContentBlock">
        <div
          className={"frameBar-vert frameBar-" + this.props.videoPlacement}
        />
        <div
          className={
            "videoFrame videoContentFrame-" + this.props.videoPlacement
          }
        >
          <div
            className={
              "videoContentText videoContentText-" + this.props.videoPlacement
            }
          >
            <h1 className="blockHeading" id={this.props.linkName}>
              {this.props.heading}
            </h1>
            <p>{this.props.text}</p>
          </div>
          <ReactPlayer
            url={this.props.videoUrl}
            width="704px"
            height="396px"
            playing={true}
            muted={true}
            loop={true}
            className={"videoContent videoContent-" + this.props.videoPlacement}
          />
        </div>
      </section>
    );
  }
}

export default VideoContentBlock;
