import React from "react";

import "../styles/footer.scss";
import "../styles/ConstStyles.scss";

import { useTranslation } from "react-i18next";

export type FooterProps = {
  idk: string;
};

const Footer = (props?: FooterProps) => {
  const { t } = useTranslation();
  const copyrightStartYear = 2021;
  const currentYear = new Date().getFullYear();
  const yearRange = `${copyrightStartYear}-${currentYear}`;
  return (
    <React.Fragment>
      <div id="footerLine" />
      <footer id="pageFooter">
        <section id="socialMediaSection">
          <div id="socialMediaInfo">
            <a
              href="https://www.youtube.com/channel/UCWD0Hh5N_75iabhEKPFrUDg"
              target="_blank"
              rel="noopener noreferrer"
              className="socialMediaIconContainer"
            >
              <img
                src="images/socialmedia/youtube.svg"
                alt={t("Youtube Icon") || ""}
                className="socialMediaIcon"
              />
            </a>
            <a
              href="https://www.twitch.tv/nowloadingesports"
              target="_blank"
              rel="noopener noreferrer"
              className="socialMediaIconContainer"
            >
              <img
                src="images/socialmedia/twitch.svg"
                alt={t("Twitch Icon") || ""}
                className="socialMediaIcon"
              />
            </a>
            <a
              href="https://twitter.com/NowLoadingOrg"
              target="_blank"
              rel="noopener noreferrer"
              className="socialMediaIconContainer"
            >
              <img
                src="images/socialmedia/twitter.svg"
                alt={t("Twitter Icon") || ""}
                className="socialMediaIcon"
              />
            </a>
            <a
              href="https://www.instagram.com/nowloadingtv/"
              target="_blank"
              rel="noopener noreferrer"
              className="socialMediaIconContainer"
            >
              <img
                src="images/socialmedia/instagram.svg"
                alt={t("Instagram Icon") || ""}
                className="socialMediaIcon"
              />
            </a>
          </div>
        </section>
        <section id="copyrightSection">
          <div id="copyrightInfo">
            {t("Copyright Full", {
              yearRange: yearRange,
              companyName: t("Now Loading"),
              allRightsReserved: t("All Rights Reserved"),
            })}
            <br />
            {`${t("Terms and Conditions")} | ${t("Privacy")} | ${t(
              "Legal Notice"
            )}`}
          </div>
        </section>
        <section id="contactSection">
          <div id="contactInfo" className="alignLeft">
            {`${t("Email:")} contact@nowloading.tv`}
            <br />
            {`${t("Phone:")} 484-213-5747`}
          </div>
        </section>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
