import React, { Component } from "react";

import "../styles/ConstStyles.scss";

class ParallaxBackground extends Component {
  state = {};
  render() {
    return <div className="Parallax"></div>;
  }
}

export default ParallaxBackground;
