import React from "react";

import ClientCarousel from "./clientCarousel";
import "../styles/clientsContentBlock.scss";
import data from "../data/clientData.json";

export interface clientData {
  name: string;
  img: string;
  alt: string;
  description: string;
}

export type clientsContentBlockProps = {
  heading: string;
  linkName: string;
};

const ClientsContentBlock = (props: clientsContentBlockProps) => {
  const { linkName, heading } = props;
  return (
    <React.Fragment>
      <div className={"frameBar-hori frameBar-top backgroundGreen"} />
      <section className="clientsSection">
        <h1
          className="blockHeading"
          style={{ paddingTop: "20px" }}
          id={linkName}
        >
          {heading}
        </h1>
        <ClientCarousel data={data} />
      </section>
    </React.Fragment>
  );
};

export default ClientsContentBlock;
