import React, { Component } from "react";
import { findRenderedDOMComponentWithClass } from "react-dom/cjs/react-dom-test-utils.production.min";

import ClientCircle from "./clientCircle";

class ClientCarousel extends Component {
  state = {
    numberOfClients: this.props.data.length,
    dataIndexPointer: 0,
  };

  constructor(props) {
    super(props);
    this.VIEWABLE = 5;
    this.TOTALCIRCLES = this.VIEWABLE + 2;
    this.colorInterval = true;

    this.cycleRight.bind(this);
    this.cycleLeft.bind(this);
  }

  getClients() {
    // Get the first 5 clients and map them to clientCircles.
    let currentIndex = this.state.dataIndexPointer;
    let viewableClientsArray = [];
    for (var i = 0; i < this.VIEWABLE; i++) {
      viewableClientsArray.push(this.props.data[currentIndex]);
      //increment the local currentIndex
      currentIndex =
        currentIndex === this.props.data.length - 1 ? 0 : currentIndex + 1;
    }
    let lastColor =
      this.colorInterval === true
        ? " circleBorder-pink"
        : " circleBorder-green";
    return viewableClientsArray.map((client, index) => {
      let circleSize;
      if (index === 0 || index === this.VIEWABLE - 1) {
        circleSize = "circleSmall";
      } else if (index === 1 || index === this.VIEWABLE - 2) {
        circleSize = "circleMedium";
      } else {
        circleSize = "circleLarge";
      }
      lastColor =
        lastColor === " circleBorder-pink"
          ? " circleBorder-green"
          : " circleBorder-pink";
      let circlePosition;
      switch (index) {
        case 0:
          circlePosition = " circleLeftTwice";
          break;
        case 1:
          circlePosition = " circleLeftOnce";
          break;
        case 2:
          circlePosition = " circleCenter";
          break;
        case 3:
          circlePosition = " circleRightOnce";
          break;
        case 4:
          circlePosition = " circleRightTwice";
          break;
        default:
          circlePosition = "";
      }
      const { name, img, alt, description } = client;
      return (
        <ClientCircle
          key={"clientCircle" + index}
          classes={circleSize.concat(lastColor).concat(circlePosition)}
          name={name}
          img={img}
          alt={alt}
          description={description}
        />
      );
    });
  }

  cycleRight() {
    let currentPointer = this.state.dataIndexPointer;
    var newPointer;
    if (currentPointer < this.props.data.length - 1) {
      newPointer = currentPointer + 1;
    } else {
      newPointer = 0;
    }
    this.setState({ dataIndexPointer: newPointer });
    this.flipColorInterval();
  }

  cycleLeft() {
    let currentPointer = this.state.dataIndexPointer;
    var newPointer;
    if (currentPointer > 0) {
      newPointer = currentPointer - 1;
    } else {
      newPointer = this.props.data.length - 1;
    }
    this.setState({ dataIndexPointer: newPointer });
    this.flipColorInterval();
  }

  flipColorInterval() {
    this.colorInterval = this.colorInterval === true ? false : true;
  }

  // Carousel knows:
  // position of the circle
  // whether it should be visible or not
  // what border color it has?
  //
  // Circle must know:
  // when to change position (aka which class to change to)
  // when to be visible (its opacity upon leaving/entering the carousel)
  //

  getDataIndexOfClient(clientName) {
    for (var i = 0; i < this.props.data.length; i++) {
      if (clientName === this.props.data[i].name) {
        return i;
      }
    }
  }

  getPositionOfCircle(circleName) {
    let pointer = this.state.dataIndexPointer;
    let dataIndex = this.getDataIndexOfClient(circleName);
    switch (dataIndex) {
      case this.decrementPointer(pointer):
        return "circleLeftThrice";
      case pointer:
        return "circleLeftTwice";
      case this.incrementPointer(pointer):
        return "circleLeftOnce";
      case this.incrementPointer(pointer, 2):
        return "circleCenter";
      case this.incrementPointer(pointer, 3):
        return "circleRightOnce";
      case this.incrementPointer(pointer, 4):
        return "circleRightTwice";
      case this.incrementPointer(pointer, 5):
        return "circleRightThrice";
    }
  }

  getSizeOfCircle(circleName) {
    let pointer = this.state.dataIndexPointer;
    let dataIndex = this.getDataIndexOfClient(circleName);
    switch (dataIndex) {
      case this.decrementPointer(pointer):
        return "circleMini";
      case pointer:
        return "circleSmall";
      case this.incrementPointer(pointer):
        return "circleMedium";
      case this.incrementPointer(pointer, 2):
        return "circleLarge";
      case this.incrementPointer(pointer, 3):
        return "circleMedium";
      case this.incrementPointer(pointer, 4):
        return "circleSmall";
      case this.incrementPointer(pointer, 5):
        return "circleMini";
    }
  }

  getVisibilityOfCircle(circleName) {
    let pointer = this.state.dataIndexPointer;
    let dataIndex = this.getDataIndexOfClient(circleName);
    switch (dataIndex) {
      case this.decrementPointer(pointer):
        return "hide";
      case pointer:
        return "show";
      case this.incrementPointer(pointer):
        return "show";
      case this.incrementPointer(pointer, 2):
        return "show";
      case this.incrementPointer(pointer, 3):
        return "show";
      case this.incrementPointer(pointer, 4):
        return "show";
      case this.incrementPointer(pointer, 5):
        return "hide";
    }
  }

  getBorderOfCircle(circleName) {
    let pointer = this.state.dataIndexPointer;
    let dataIndex = this.getDataIndexOfClient(circleName);
    switch (dataIndex) {
      case this.decrementPointer(pointer):
        return "hide";
      case pointer:
        return "show";
      case this.incrementPointer(pointer):
        return "show";
      case this.incrementPointer(pointer, 2):
        return "show";
      case this.incrementPointer(pointer, 3):
        return "show";
      case this.incrementPointer(pointer, 4):
        return "show";
      case this.incrementPointer(pointer, 5):
        return "hide";
    }
  }

  incrementPointer(indexOfPointer, incrementBy = 1) {
    let currentIndex = indexOfPointer;
    for (let i = 0; i < incrementBy; i++) {
      currentIndex =
        currentIndex === this.props.data.length - 1 ? 0 : currentIndex + 1;
    }
    return currentIndex;
  }

  decrementPointer(indexOfPointer, decrementBy = 1) {
    let currentIndex = indexOfPointer;
    for (let i = 0; i < decrementBy; i++) {
      currentIndex =
        currentIndex === 0 ? this.props.data.length - 1 : currentIndex - 1;
    }
    return currentIndex;
  }

  render() {
    return (
      <div className="clientCarousel">
        <button onClick={this.cycleRight.bind(this)}>Left</button>
        <div className="clientFlex">
          {/*{this.getClients()}*/}
          {this.props.data.map((client, index) => {
            let borderColor =
              index % 2 === 0 ? "circleBorder-green" : "circleBorder-pink";
            return (
              <ClientCircle
                name={client.name}
                img={client.img}
                alt={client.alt}
                border={borderColor}
                description={client.description}
                getPosition={this.getPositionOfCircle.bind(this)}
                getSize={this.getSizeOfCircle.bind(this)}
                getVisibility={this.getVisibilityOfCircle.bind(this)}
              />
            );
          })}
        </div>
        <button onClick={this.cycleLeft.bind(this)}>Right</button>
      </div>
    );
  }
}

export default ClientCarousel;
