import React, { Component } from "react";
import {
  Autocomplete,
  Button,
  createFilterOptions,
  FormControl,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";

import locations from "../data/50kmTowns.json";
import "../styles/businessContactForm.scss";

import { useTranslation } from "react-i18next";

export type ContactFormProps = {
  heading: string;
};

const BusinessContactForm = (props?: ContactFormProps) => {
  const { t } = useTranslation();
  const autocompleteFilterOptions = createFilterOptions({
    matchFrom: "start",
    limit: 5,
  });

  const textFieldInputProps = {
    autoComplete: "off",
  };
  const textFieldLabelProps = {
    style: { fontFamily: "'Exo', sans-serif", width: "100%" },
  };

  return (
    <React.Fragment>
      <div className={"frameBar-hori frameBar-top backgroundPink"} />
      <section id="contactFormSection">
        <h1
          className="blockHeading"
          style={{ paddingTop: "20px" }}
          id="Business"
        >
          {t("Business")}
        </h1>
        <p style={{ width: "70%", margin: "auto" }}>{t("Lorem ipsum")}</p>
        <div className="formContainer">
          <div id="formRow1" className="formRow formRow-top">
            <TextField
              type="text"
              id="fnameInput"
              variant="filled"
              label={t("First Name")}
              InputLabelProps={textFieldLabelProps}
              autoComplete="given-name"
            />
            <TextField
              type="text"
              id="emailInput"
              variant="filled"
              label={t("Email Address")}
              InputLabelProps={textFieldLabelProps}
              autoComplete="email"
            />
            <TextField
              type="text"
              id="companyInput"
              variant="filled"
              label={t("Company Name")}
              InputLabelProps={textFieldLabelProps}
              autoComplete="organization"
            />
          </div>
          <div id="formRow2" className="formRow formRowCenter">
            <TextField
              type="text"
              id="lnameInput"
              variant="filled"
              label={t("Last Name")}
              InputLabelProps={textFieldLabelProps}
              autoComplete="family-name"
            />
            <TextField
              type="text"
              id="addressInput"
              variant="filled"
              label={t("Street Address")}
              InputLabelProps={textFieldLabelProps}
              autoComplete="street-address"
            />
            <Autocomplete
              id="locationAutocompleteInput"
              options={locations}
              filterOptions={autocompleteFilterOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="locationInput"
                  variant="filled"
                  label={t("Location")}
                  InputLabelProps={textFieldLabelProps}
                  autoComplete="address-level2"
                  //InputProps={{ style: { minWidth: "200px" } }}
                />
              )}
            />
            <TextField
              type="text"
              id="phoneInput"
              variant="filled"
              label={t("Phone Number")}
              InputLabelProps={textFieldLabelProps}
              autoComplete="tel-national"
            />
          </div>
          <div id="formRow3" className="formRow formRow-center">
            <TextField
              type="text"
              id="moreInput"
              variant="filled"
              label={t("Tell us more")}
              InputLabelProps={textFieldLabelProps}
              InputProps={{ style: { height: "180px" } }}
              inputProps={{ style: { paddingTop: "0px" } }}
              multiline={true}
              rows={6}
              autoComplete="off"
              fullWidth
            />
          </div>
          <div id="formRow4" className="formRow formRow-bottom">
            <Button variant="contained">{t("Get a Quote")}</Button>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default BusinessContactForm;
