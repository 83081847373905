import React, { Component } from 'react'
import './styles/ConstStyles.scss'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link
} from 'react-router-dom'

import i18next from 'i18next'
import { useTranslation, initReactI18next } from 'react-i18next'
import Text from './data/textLocalization.json'

import './App.scss'
import Header from './components/header'
import VideoContentBlock from './components/videoContentBlock'
import ClientsContentBlock from './components/clientsContentBlock'
import BusinessContactForm from './components/businessContactForm'
import Footer from './components/footer'

import ParallaxBackground from './components/parallaxBackground'

import $ from 'jquery'

import englishLocalization from './localization/en.json'
import spanishLocalization from './localization/es.json'
import frenchLocalization from './localization/fr.json'

i18next.use(initReactI18next).init({
  resources: {
    en: englishLocalization,
    es: spanishLocalization,
    fr: frenchLocalization
  },
  lng: 'en',
  fallbackLng: 'en',
  useDataAttrOptions: true,
  interpolation: {
    escapeValue: false
  }
})

//Applying offset
function offsetAnchor () {
  console.log('OFFSET ANCHOR')
  // eslint-disable-next-line no-restricted-globals
  if (location.hash.length !== 0) {
    window.scrollTo(window.scrollX, window.scrollY - 100)
  }
}

//Captures click events of all <a> elements with href starting with #
$(document).on('click', 'a[href^="%"]', function (event) {
  // Click events are captured before hashchanges. Timeout
  // causes offsetAnchor to be called after the page jump.
  window.setTimeout(function () {
    offsetAnchor()
  }, 0)
})

function App () {
  let lang = 'eng' //'spa' for Spanish, 'fre' for French [NOT IMPLEMENTED]
  const { t } = useTranslation()

  return (
    <div className='AppContainer'>
      <Router>
        <Routes>
          <Route
            exact
            path={'/'}
            element={[
              <Header />,
              <ParallaxBackground />,
              <VideoContentBlock
                heading={t('Esports')}
                text={t('Lorem ipsum')}
                blockId='videoBlock1'
                linkName={t('Esports')}
                videoUrl='../videos/Satisfactory.mp4'
                videoPlacement='right'
              />,
              <ParallaxBackground />,
              <VideoContentBlock
                heading={t('Streaming')}
                text={t('Lorem ipsum')}
                blockId='videoBlock2'
                linkName={t('Streaming')}
                videoUrl='../videos/Gungeon.mp4'
                videoPlacement='left'
              />,
              <ParallaxBackground />,
              <ClientsContentBlock
                heading={t('Clients & Partners')}
                linkName={t('Clients')}
              />,
              <ParallaxBackground />,
              <BusinessContactForm
                heading={t('Business')}
                text={t('Lorem ipsum')}
                formFields={Text.contact.fields[lang]}
                submit={t('Get a Quote')}
              />,
              <Footer />
            ]}
          />
        </Routes>
      </Router>
    </div>
  )
}

export default App
