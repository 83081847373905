import React, { Component } from "react";

class ClientCircle extends Component {
  state = {};
  render() {
    return (
      <div className="clientCircleWrapper">
        <div
          className={"clientCircle"
            .concat(" " + this.props.getPosition(this.props.name))
            .concat(" " + this.props.getSize(this.props.name))
            .concat(" " + this.props.getVisibility(this.props.name))
            .concat(" " + this.props.border)}
          style={{ backgroundImage: `url("${this.props.img}")` }}
        ></div>
      </div>
    );
  }
}

export default ClientCircle;
